import React from 'react';





export default function LandingPage() {
    return (
        <div>
           

            <h1 className="text-5xl mt-60 font-bold underline">
               
            </h1>
            <img className="ml-80 pl-40" src="https://sp-ao.shortpixel.ai/client/to_webp,q_glossy,ret_img,w_1404,h_427/https://houseofpubs.com/wp-content/uploads/2021/09/hop.png" alt="HoP"  width="800" height="500" />
        </div>
    )
}