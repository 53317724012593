import React from 'react';


export default function CheckBox({enable, handleCheckBox, id, name }) {
    return (

        <div className="form-check">
            <input id={id} name={name} value={enable} checked= {enable===false ? "checked" : ""} onChange={handleCheckBox} className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-2 align-top bg-no-repeat bg-center bg-contain float-left mx-2 cursor-pointer" type="checkbox" />
        </div>

    )
}

