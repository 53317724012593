import React from 'react';






export default function Filter({ handleFilterSsp, handleFilterStatus, name, handleName, handleSubmit, value, selectStatus, handleRefresh }) {
  return (
    <div className="relative inline-flex">
      <button type="button" onClick={(e) => handleRefresh(e)} className="text-sm  bg-yellow-500 hover:bg-yellow-600 text-white py-2 mx-10 px-2 rounded focus:outline-none focus:shadow-outline">Reset Filter</button>

      <select onChange={e => handleFilterSsp(e)} value={value} className=" border border-gray-300 mx-4 text-sm h-10  pl-2 pr-2 bg-gray-200 hover:border-gray-400 focus:outline-none appearance-none">
        <option value="all" >ALL SSP</option>
        <option value="mcm">MCM</option>
        <option value="one_tag" >One Tag</option>
        <option value="xandr" >Xandr</option>
        <option value="smart_adserver" >Smart AdServer</option>
        <option value="improve">Improve</option>
        <option value="pulsepoint" >Pulsepoint</option>
        <option value="pubmatic" >pubmatic</option>
        <option value="Outbrain" >Outbrain</option>
        <option value="adtelligent" >adtelligent</option>
        <option value="sovrn" >Sovrn</option>
        <option value="index_pub" >Index</option>
      </select>

      {value !== "all" ? <select onChange={e => handleFilterStatus(e)} value={selectStatus} className=" border border-gray-300 text-sm h-10  mr-20 pl-2 pr-2 bg-gray-200 hover:border-gray-400 focus:outline-none appearance-none">
        <option value="">SELECT STATE</option>
        <option value="Not Send">NOT SEND</option>
        <option value="Pending" >PENDING</option>
        <option value="Yes" >YES</option>
        <option value="No" >NO</option>

      </select> : ""}

      <div className="flex items-center justify-center ">
        <div className="flex border-2 border-gray-200 rounded">
          <input onChange={(e) => handleName(e)} value={name} type="text" className="px-4 py-2 w-80" placeholder="Publisher Name..." />
          <button onClick={(e) => handleSubmit(e)} type='submit' className="px-6 text-lg font-medium text-white bg-blue-500 rounded-r hover:bg-blue-600">
            Filter
          </button>
        </div>
      </div>
    </div>
  )

}