const initialState = {
  publishers: [],
  sites: [],
  publisher: [],
  tags: [],
  seller: {},
  site: {},
  tracks: [],
  tracksFilt: []
}

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case 'GET_PUBLISHERS':
      return {
        ...state,
        publishers: action.payload,
      };
    case 'GET_PUBLISHER_BY_ID':
      return {
        ...state,
        publisher: action.payload,
      };
    case 'GET_PUBLISHER_BY_NAME':
      return {
        ...state,
        publishers: action.payload,
      };

    case 'DELETE_PUBLISHER':
      return {
        ...state,
        publishers: state.publishers.filter(({ id }) => +id !== +action.payload.id)
      };

    case 'POST_PUBLISHER':
      return {
        ...state
      };
    case 'PUT_PUBLISHER':
      return {
        ...state,
        publishers: action.payload
      };

    case 'GET_SITES':
      return {
        ...state,
        sites: action.payload,
      };
    case 'GET_SELLER_JSON':
      return {
        ...state,
        seller: action.payload,
      };
    case 'GET_SITE_BY_ID':
      return {
        ...state,
        site: action.payload,
      };
    case 'POST_SITE':
      return {
        ...state
      };
    case 'DELETE_SITE':
      return {
        ...state
      };
    case 'GET_TAGS':
      return {
        ...state,
        tags: action.payload,
      };
    case 'GET_TAG_BY_ID':
      return {
        ...state,
        tags: action.payload,
      };
    case 'DELETE_TAG':
      return {
        ...state
      };
    case 'POST_TAG':
      return {
        ...state,
        tags: action.payload,
      };
    case 'PUT_TAG':
      return {
        ...state,
      };
    case 'GET_TRACKS':
      return {
        ...state,
        tracks: action.payload,
        tracksFilt: action.payload
      };
    case 'GET_TRACK_BY_NAME':
      return {
        ...state,
        tracks: action.payload,
      };
    case 'PUT_TRACK':
      return {
        ...state,
        
      };

    case 'FILTER_BY_STATUS':
      return {
        ...state,
        tracks: action.payload,
      };
      
    default:
      return state;
  }
}

export default rootReducer;