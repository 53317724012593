import React, { useState, useEffect } from "react";
import { getPublisherById, putPublisher } from '../../actions';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import swal from 'sweetalert';



export default function EditPublisher() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const publisher = useSelector((state) => state.publisher);
    const [input, setInput] = useState({
        publisher_Name: "",
        legal_Entity_Name: "",
        info_Payment: "",
        rev_Share:"",
        id: id
    })

    useEffect(() => {
        dispatch(getPublisherById(id))
    }, [])

    function handleSubmit(e) {
        e.preventDefault();
        dispatch(putPublisher(input))
        swal({
            title: "Publisher edited",
            icon: "success",
            text: "The publisher was successfully edited"
        }).then(() => navigate('/publishers', { replace: true }))

    }

    function handleInputChange(e) {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        })
    }

    return (
        <div>
            <h1 className="text-5xl mt-32 font-bold ">
                Edit Publisher
            </h1>
            <div className="max-w-2xl mx-auto bg-white p-16">
                <form onSubmit={e => handleSubmit(e)}>
                    <div className="grid gap-6 mb-6 ">
                        <div>
                            <label for="publisher_Name" className="block mb-2 text-sm text-left pl-1 font-medium text-gray-900 ">Publisher Name</label>
                            <input type="text" onChange={(e) => handleInputChange(e)} name="publisher_Name" id="publisher_Name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder={publisher.publisher_Name} />
                        </div>
                        <div>
                            <label for="legal_Entity_Name" className="block mb-2 text-sm text-left pl-1 font-medium text-gray-900 ">Legal Entity Name</label>
                            <input type="text" onChange={(e) => handleInputChange(e)} name="legal_Entity_Name" id="legal_Entity_Name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder={publisher.legal_Entity_Name} />
                        </div>
                        <div>
                            <label for="info_Payment" className="block mb-2 text-sm text-left pl-1 font-medium text-gray-900 ">Info Payment</label>
                            <input type="text" onChange={(e) => handleInputChange(e)} name="info_Payment" id="info_Payment" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder={publisher.info_Payment} />
                        </div>
                        <div>
                            <label className="block mb-2 text-sm text-left pl-1  font-medium text-gray-900 ">Rev Share</label>
                            <input type="number" onChange={(e) => handleInputChange(e)} name="rev_Share" id="rev_Share" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder= {publisher.rev_Share} />
                        </div>
                    </div>
                    <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center ">Submit</button>
                </form>
            </div>
        </div>
    )
}