import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { getPublishers, getPublisherByName, putPublisher } from '../../actions';
import swal from 'sweetalert';
import Pagination from '../Pagination/Pagination';
import SearchBar from '../../SearchBar/SearchBar';
import Toggle from '../Toggle/Toggle';
import CheckBox from '../Toggle/CheckBox';



export default function Publishers() {

    const dispatch = useDispatch();
    const allPublishers = useSelector((state) => state.publishers);
    const [currentPage, setCurrentPage] = useState(1);
    const [hide, setHide] = useState(true)
    const [publishersPage,] = useState(20);
    const [refresh, setRefresh] = useState(true)
    var PublishersFilter = []
    if (hide) {
        PublishersFilter = allPublishers.filter(e => e.enable !== false)
    } else {
        PublishersFilter = allPublishers
    }

    const indexLast = currentPage * publishersPage;
    const indexFirst = indexLast - publishersPage;
    const currentPublishers = PublishersFilter.slice(indexFirst, indexLast);
    const [name, setName] = useState('');
    const [input, setInput] = useState({ id: "", enable: "" })
    const pagination = (pageNumber) => {
        setCurrentPage(pageNumber)
    }
    


    useEffect(() => {
        dispatch(getPublishers())
    }, [refresh, dispatch])

    useEffect(() => {
        if (input.id) {
            dispatch(putPublisher(input))
        }

    }, [input, dispatch])

    function handleInput(e) {
        e.preventDefault();
        setName(e.target.value)
    }

    function handleSubmit(e) {
        e.preventDefault();
        dispatch(getPublisherByName(name))
        setName("")
    }
    function handleHide(e) {
        if (hide === false) {
            setHide(true)
        } else {
            setHide(false)
        }
    }
    function handleCheckBox(e) {

        if (e.target.value === "false") {
            swal({
                title: "Do you want to enable this publisher?",
                text: "If you enable the publisher, all associated information will be enabled",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((value) => {
                if (value === true) {
                    swal(`Publisher has been successfully enabled`, {
                        icon: "success",
                    });
                    setInput({
                        enable: "true",
                        id: e.target.id
                    })
                } else swal(`Publisher has not been enabled!`);
            });
            
        } else {
            swal({
                title: "Do you want to disable this Publisher?",
                text: "If you disable the publisher, all associated information will be disabled",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((value) => {
                if (value === true) {
                    swal(`Publisher has been successfully disabled`, {
                        icon: "success",
                    });
                    setInput({
                        enable: "false",
                        id: e.target.id
                    })
                } else swal(`Publisher has not been disabled!`);
            });
           
        }
        if (refresh === false) {
            setRefresh(true)
        } else {
            setRefresh(false)
        }
    }


   

    return (
        <div className="text-gray-900">
            <div className="text-gray-900 ">
                <div className="p-4 flex flex justify-between ">
                    <div className="flex ml-2">
                        <h1 className="text-4xl">
                            Publishers
                        </h1>
                        <Link to='addpublisher'><button type="button" className="text-sm  bg-green-500 hover:bg-green-700 text-white py-2 mx-10 px-2 rounded focus:outline-none focus:shadow-outline">Add Publisher</button></Link>
                    </div>
                    <div className="mr-14">
                        <SearchBar handleInput={handleInput} handleSubmit={handleSubmit} name={name} />
                    </div>
                </div>
                <Pagination currentPage={currentPage} entriesPerPage={publishersPage} totalEntries={PublishersFilter.length} pagination={pagination} />
                <div className="mt-10 ml-6 flex flex justify-left ">
                    <Toggle handleHide={handleHide} label={"Show Inactive Publishers"} />
                </div>
                <div className="px-3 py-4 flex justify-center">
                    <table className="w-full text-md bg-white shadow-md rounded mb-4 table-fixed">
                        <thead>
                            <tr className="border-b ">
                                <th className="text-left w-14 p-1 px-3"></th>
                                <th className="text-left w-40 p-3">id</th>
                                <th className="text-left p-3 px-5">Legal Entity Name</th>
                                <th className="text-left p-3 px-5">Publisher Name</th>
                                <th className="text-left p-3 px-5">Info Payment</th>
                                <th className="text-left p-3 px-5">Rev Share </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentPublishers && currentPublishers.map((e) => {
                                return (
                                    <tr key={"group" + e.id} className={`${e.enable === false ? "text-gray-400 line-through" : ""}  border-b `}>
                                        <td key={e.id + "enable"} className=" px-3"> <CheckBox id={e.id} name="enable" handleCheckBox={handleCheckBox} enable={e.enable} />  </td>
                                        <td key={e.id} className="text-left  p-3 px-5">{e.id} </td>
                                        <td key={e.legal_Entity_Name} className="text-left overflow-hidden p-3 px-5">{e.legal_Entity_Name}</td>
                                        <td key={e.publisher_Name + "name"} className="text-left p-3 px-5">{e.publisher_Name}</td>
                                        <td key={e.info_Payment + "payment"} className="text-left p-3 px-5">{e.info_Payment} </td>
                                        <td key={e.rev_Share + "revenue"} className="text-left p-3 px-5">{e.rev_Share + "%"} </td>
                                        <td key={"buttons" + e.id} className="text-left p-3 px-5 flex justify-end">
                                            {e.enable === true ? <Link to={"/publisher/editpublisher/" + e.id}><span key={"edit" + e.id} type="button" className="mr-3 text-lg text-blue-500 hover:text-blue-800">Edit</span></Link> : <></>}
                                            {e.enable === true ? <Link key={"view" + e.id} to={"/publisher/" + e.id}><button type="button" className="text-sm bg-green-500 hover:bg-green-700 text-white py-1 mx-10 px-2 rounded focus:outline-none focus:shadow-outline">View Sites</button></Link> : <></>}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div >
    )
}