import React, { useEffect, useState } from "react";
import { getPublisherById, deleteSite, getSiteById } from '../../actions';
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';



export default function PublisherSites() {

    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const publisher = useSelector((state) => state.publisher);
    const [refresh, setRefresh]= useState(true)
    useEffect(() => {
        dispatch(getPublisherById(id))
    }, [dispatch, refresh, id])

    function handleDelete(e) {
        swal({
            title: "Do you want delete site?",
            text: "If you delete the site, all associated tags information will be lost",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((value) => {
            if (value === true) {
                swal(`Site ${e.target.name} has been successfully deleted`, {
                    icon: "success",
                });
                dispatch(deleteSite(e.target.value))
                setRefresh(false)
                    navigate('/publisher/' + id, { replace: true })
            } else swal(`Publisher has not been deleted!`);
        });
    }

    function handleChange(e){
    
        
    dispatch(getSiteById(e.target.value)).then( navigate('/publisher/editsite/' +  e.target.value, { replace: true }))
   
    }

    return (
        <div className="text-gray-900 ">
            <div className="text-gray-900 ">
                <div className="p-4 flex">
                    <h1 className="text-3xl">
                        {publisher.publisher_Name}  Sites
                    </h1>
                    <Link to={'/publisher/addsite/' + publisher.id}><button type="button" className="text-sm bg-green-500 hover:bg-green-700 text-white py-2 mx-10 px-4 rounded focus:outline-none focus:shadow-outline">Add Site</button></Link>
                </div>

                <div className="px-3 py-4 flex justify-center">
                    <table className="w-full text-md bg-white shadow-md rounded mb-4 table-fixed">
                        <thead>
                            <tr className="border-b ">
                                <th className="w-40 text-left p-3">id</th>
                                <th className="text-left p-3 px-5">Domain</th>
                                <th className="text-left p-3 px-5">Geos</th>
                                <th className="text-left p-3 px-5">Seller Type</th>
                                <th className="text-left p-3 px-5">Ads.txt</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {publisher.sites && publisher.sites.map((e) => {
                                return (
                                    <tr key={"group" + e.id} className="border-b ">
                                        <td key={e.id} className="p-3 px-5 text-left" >{e.id} </td>
                                        <td key={"domain" +e.id} className="p-3 px-5 text-left" >{e.domain?e.domain:""} </td>
                                        <td key={"geos" +e.id} className="p-3 px-5 text-left" >{e.geos? e.geos.toString():""}  </td>
                                        <td key={"seller" + e.id} className="p-3 px-5 text-left" >{e.seller_type?e.seller_type:""} </td>
                                        <td key={"ads" + e.id} className="text-left  p-3 px-5">{"houseofpubs.com, " + e.id + ", " + (e.seller_type?e.seller_type:"")}</td>
                                        <td key={"buttons" + e.id} className="p-3 px-5 text-left  flex justify-end">
                                            <button key={"edit" + e.id} onClick={(e) => handleChange(e)} value={e.id} type="button" className="mr-3 text-lg text-blue-500 hover:text-blue-800">Edit</button>
                                            <button key={"delete" + e.id} type="button" onClick={(e) => handleDelete(e)} value={e.id} className="text-sm bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline">Delete</button>
                                            <Link to={'/publisher/sites/' + e.id}><button key={"view" + e.id} type="button" className="text-sm bg-green-500 hover:bg-green-700 text-white py-1 mx-10 px-2 rounded focus:outline-none focus:shadow-outline">View Tags</button></Link>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}