import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getSites, getSellerJson } from '../../actions';
import Pagination from '../Pagination/Pagination';


export default function Sites() {
    const dispatch = useDispatch();
    const allSites = useSelector((state) => state.sites);
    const sellerJson = useSelector((state) => state.seller);
    const [currentPage, setCurrentPage] = useState(1);
    const [SitesPage,] = useState(20);
    const indexLast = currentPage * SitesPage;
    const indexFirst = indexLast - SitesPage;
    const currentSites = allSites.slice(indexFirst, indexLast);


    const pagination = (pageNumber) => {
        setCurrentPage(pageNumber)
    }

    useEffect(() => {
        dispatch(getSites())
        dispatch(getSellerJson())
    }, [dispatch])

    
    function downloadObjectAsJson(exportObj, exportName) {
        var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
        var downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href", dataStr);
        downloadAnchorNode.setAttribute("download", exportName + ".json");
        document.body.appendChild(downloadAnchorNode);
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
      }

      function handleDownload(e) {
        downloadObjectAsJson(sellerJson,'Sellers')
    }
    return (
        <div className="text-gray-900 ">
            <div className="text-gray-900 ">
                <div className="p-4 flex">
                    <h1 className="text-4xl">
                        Sites
                    </h1>
                    <button onClick={(e) => handleDownload(e)} type="button" className="text-sm bg-green-500 hover:bg-green-700 text-white py-2 mx-10 px-2 rounded focus:outline-none focus:shadow-outline">Sellers.JSON</button>
                </div>
                <Pagination currentPage={currentPage} entriesPerPage={SitesPage} totalEntries={allSites.length} pagination={pagination} />
                <div className="px-3 py-4 flex justify-center">
                    <table className="w-full text-md bg-white shadow-md rounded mb-4 table-fixed">
                        <thead>
                            <tr className="border-b ">
                                <th className="w-40 text-left p-3 px-5">id</th>
                                <th className="text-left p-3 px-5">Publisher</th>
                                <th className="text-left p-3 px-5">Domain</th>
                                <th className="text-left p-3 px-5">Geos</th>
                                <th className="text-left p-3 px-5">Seller Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentSites && currentSites.map((e) => {
                                return (
                                    <tr key={"group" + e.id} className="border-b ">
                                        <td key={e.id} className="text-left p-3 px-5">{e.id}</td>
                                        <td key={e.publisher.publisher_Name} className="text-left p-3 px-5">{e.publisher.publisher_Name}</td>
                                        <td key={e.id + e.domain} className="text-left p-3 px-5">{e.domain} </td>
                                        <td key={e.geos} className="text-left p-3 px-5">{e.geos? e.geos.toString():""}</td>
                                        <td key={e.seller_type} className="text-left p-3 px-5">{e.seller_type}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}