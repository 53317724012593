import React from 'react';





export default function SearchBar({handleInput, handleSubmit, name }) {
    return (
        <div>
            <div className="flex items-center justify-center ">
                <div className="flex border-2 border-gray-200 rounded">
                    <input onChange ={(e) =>handleInput(e)} value={name} type="text" className="px-4 py-2 w-80" placeholder="Search..."/>
                        <button onClick={(e) => handleSubmit(e)} type ='submit' className="px-4 text-sm font-medium text-white bg-blue-500 rounded-r hover:bg-blue-600">
                            Search
                        </button>
                </div>
            </div>
        </div>
    )
}



