import axios from 'axios';

export function getPublishers() {
    return async function (dispatch) {
        var response = await axios.get('https://direct.houseofpubs.com/api/getPublishers');
        return dispatch({
            type: 'GET_PUBLISHERS',
            payload: response.data
        })
    }
}
export function getPublisherById(id) {
    return async function (dispatch) {
        var response = await axios.get('https://direct.houseofpubs.com/api/getPublishers?id=' + id);
        return dispatch({
            type: 'GET_PUBLISHER_BY_ID',
            payload: response.data
        })
    }
}
export function getPublisherByName(name) {
    return async function (dispatch) {
        var response = await axios.get('https://direct.houseofpubs.com/api/getPublishers?name=' + name);
        return dispatch({
            type: 'GET_PUBLISHER_BY_NAME',
            payload: response.data
        })
    }
}


export function deletePublisher(id) {
    return async function (dispatch) {
        var response = await axios.delete('https://direct.houseofpubs.com/api/deletePublisher?id=' + id);
        return dispatch({
            type: 'DELETE_PUBLISHER',
            payload: { ...response.data, id }
        })
    }
}

export function postPublisher(payload) {
    return async function (dispatch) {
        var response = await axios.post('https://direct.houseofpubs.com/api/postPublisher', payload);
        return response


    }
}
export function putPublisher(payload) {
    return async function (dispatch) {
        var response = await axios.put('https://direct.houseofpubs.com/api/putPublisher', payload);
        console.log(response.data)
        return dispatch({
            type: 'PUT_PUBLISHER',
            payload: response.data
        })

    }
}

export function getSites() {
    return async function (dispatch) {
        var response = await axios.get('https://direct.houseofpubs.com/api/getSites');
        return dispatch({
            type: 'GET_SITES',
            payload: response.data
        })
    }
}
export function getSellerJson() {
    return async function (dispatch) {
        var response = await axios.get('https://direct.houseofpubs.com/api/getSellerJson');
        return dispatch({
            type: 'GET_SELLER_JSON',
            payload: response.data
        })
    }
}
export function getSiteById(id) {
    return async function (dispatch) {
        var response = await axios.get('https://direct.houseofpubs.com/api/getSites?id=' + id);
        return dispatch({
            type: 'GET_SITE_BY_ID',
            payload: response.data
        })
    }
}

export function postSite(payload) {
    return async function (dispatch) {
        var response = await axios.post('https://direct.houseofpubs.com/api/postSite', payload);
        return response


    }
}

export function putSite(payload) {
    return async function (dispatch) {
        var response = await axios.put('https://direct.houseofpubs.com/api/putSite', payload);
        return response


    }
}
export function deleteSite(id) {
    return async function (dispatch) {
        var response = await axios.delete('https://direct.houseofpubs.com/api/deleteSite?id=' + id);
        return response

    }
}
export function getTags(id) {
    return async function (dispatch) {
        var response = await axios.get('https://direct.houseofpubs.com/api/getTags?id=' + id);
        return dispatch({
            type: 'GET_TAGS',
            payload: response.data
        })
    }
}
export function getTagById(id) {
    return async function (dispatch) {
        var response = await axios.get('https://direct.houseofpubs.com/api/getTagById?id=' + id);
        return dispatch({
            type: 'GET_TAG_BY_ID',
            payload: response.data
        })
    }
}
export function postTag(payload) {
    return async function (dispatch) {
        var response = await axios.post('https://direct.houseofpubs.com/api/postTag', payload);
        return dispatch({
            type: 'POST_TAG',
            payload: response.data
        })


    }
}
export function putTag(payload) {
    return async function (dispatch) {
        var response = await axios.put('https://direct.houseofpubs.com/api/putTag', payload);
        return dispatch({
            type: 'PUT_TAG',
            payload: response.data
        })


    }
}
export function deleteTag(id) {
    return async function (dispatch) {
        var response = await axios.delete('https://direct.houseofpubs.com/api/deleteTag?id=' + id);
        return response

    }
}
export function getTracks() {
    return async function (dispatch) {
        var response = await axios.get('https://direct.houseofpubs.com/api/getTracks');
        return dispatch({
            type: 'GET_TRACKS',
            payload: response.data
        })
    }
}
export function getTrackByName(name) {
    return async function (dispatch) {
        var response = await axios.get('https://direct.houseofpubs.com/api/getTracks?name=' + name);
        return dispatch({
            type: 'GET_TRACK_BY_NAME',
            payload: response.data
        })
    }
}
export function putTrack(payload) {
    return async function (dispatch) {
        var response = await axios.put('https://direct.houseofpubs.com/api/putTrack', payload);

        return dispatch({
            type: 'PUT_TRACK',
            payload: response.data

        })
    }
}
export function filterTrack(payload) {
    return async function (dispatch) {
        var response = await axios.post('https://direct.houseofpubs.com/api/getTrackFilter', payload);
        return dispatch({
            type: 'FILTER_BY_STATUS',
            payload: response.data

        })

    }
}

