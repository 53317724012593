import React, { useState } from "react";
import { postTag } from '../../actions';
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import swal from 'sweetalert';

export default function AddTag() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [input, setInput] = useState({
        format: "",
        type: "",
        size: "",
        siteId: id
    })
    

    function handleSubmit(e) {
        e.preventDefault();
        if (!input.format || !input.type || !input.size) {
            e.preventDefault();
            swal({
                title: "You must complete all the information",
                icon: "error"
            })
        } else {
            e.preventDefault();
            swal({
                title: "Tag created",
                icon: "success",
                text: "The Tag was successfully created"
            })
            dispatch(postTag(input)).then(navigate('/publisher/sites/' + id, { replace: true }))


        }
    }

    function handleInputChange(e) {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        })
    }




    return (
        <div>
            <h1 className="text-5xl mt-20 mb-20 font-bold">
                Add New Tag
            </h1>
            <div className="max-w-2xl mx-auto bg-white">
                <form onSubmit={e => handleSubmit(e)}>
                    <div className="grid gap-6 mb-6 ">
                        <div className="flex justify-center ">
                            <div >
                                <label  className="block mb-6 text-lg font-medium text-gray-900 ">Format</label>
                                <fieldset onChange={(e) => handleInputChange(e)}  >
                                    <div className="grid grid-cols-5">
                                        <div className="flex items-center mb-4">
                                            <input id="format-option-1" type="radio" name="format" value="Display" className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 " />
                                            <label  className="block ml-2 text-sm font-medium text-gray-900 ">
                                                Display
                                            </label>
                                        </div>
                                        <div className="flex items-center mb-4">
                                            <input id="format-option-2" type="radio" name="format" value="Video" className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 " />
                                            <label  className="block ml-2 text-sm font-medium text-gray-900 ">
                                                Video
                                            </label>
                                        </div>
                                        <div className="flex items-center mb-4">
                                            <input id="format-option-3" type="radio" name="format" value="Native" className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 " />
                                            <label  className="block ml-2 text-sm font-medium text-gray-900 ">
                                                Native
                                            </label>
                                        </div>
                                        <div className="flex items-center mb-4">
                                            <input id="format-option-4" type="radio" name="format" value="FloorAd" className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 " />
                                            <label  className="block ml-2 text-sm font-medium text-gray-900 ">
                                                FloorAd
                                            </label>
                                        </div>
                                        <div className="flex items-center mb-4">
                                            <input id="format-option-5" type="radio" name="format" value="Interstitial" className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 " />
                                            <label  className="block ml-2 text-sm font-medium text-gray-900 ">
                                                Interstitial
                                            </label>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>

                        <div>
                            <label className="block mb-6 text-lg font-medium text-gray-900 ">Type</label>
                            <fieldset onChange={(e) => handleInputChange(e)}  >
                                <div className="flex flex-nowrap justify-center ">
                                    <div className="flex items-center mb-4">
                                        <input id="type-option-1" type="radio" name="type" value="Desktop" className="w-4 h-4 ml-6 border-gray-300 focus:ring-2 focus:ring-blue-300 " />
                                        <label className="block ml-2 text-sm font-medium text-gray-900 ">
                                            Desktop
                                        </label>
                                    </div>
                                    <div className="flex items-center mb-4">
                                        <input id="type-option-2" type="radio" name="type" value="Mobile" className="w-4 h-4 ml-6 border-gray-300 focus:ring-2 focus:ring-blue-300 " />
                                        <label className="block ml-2 text-sm font-medium text-gray-900 ">
                                            Mobile
                                        </label>
                                    </div>
                                    <div className="flex items-center mb-4">
                                        <input id="type-option-3" type="radio" name="type" value="Both" className="w-4 h-4 ml-6 border-gray-300 focus:ring-2 focus:ring-blue-300 " />
                                        <label className="block ml-2 text-sm font-medium text-gray-900 ">
                                            Both
                                        </label>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                        <div >
                            <label className="block mb-2 text-lg font-medium text-gray-900 ">Size</label>
                            <div className="flex flex-nowrap justify-center ">
                                <input type="text" onChange={(e) => handleInputChange(e)} name="size" id="info_Payment" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 p-2.5 " placeholder="e.g. 250x120" />
                                </div>
                        </div>
                    </div>
                    <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center ">Submit</button>
                </form>
            </div>
        </div>
    )
}