import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";






export default function NavBar() {
    const { logout } = useAuth0();
    const { loginWithRedirect } = useAuth0();
    const { user, isAuthenticated, isLoading } = useAuth0();


    return (
        <div>
            {isLoading ?<h1> </h1>: <></>}
            
            <nav className="flex items-center justify-between flex-wrap bg-gray-300 p-6">
                <div className="flex items-center ml-10 flex-shrink-0 text-white mr-6">
                    
                    <img src="https://raw.githubusercontent.com/DamianYerien/DamianYerien/main/hop%20(1).png"  width="180" height="90" alt="hop" />
                </div>
                
                <div className="w-full block  ml-12 flex-grow lg:flex lg:items-center lg:w-auto">
                    <div className="text-left lg:flex-grow">
                        {isAuthenticated ? <a href="/publishers" className="block ml-10 text-lg lg:inline-block lg:mt-0 text-gray-600 hover:text-white mr-4" >
                            Publishers
                        </a> : <></>}
                        {isAuthenticated ? <a href="/sites" className="block ml-10 text-lg lg:inline-block lg:mt-0 text-gray-600 hover:text-white mr-4">
                            Sites
                        </a> : <></>}
                       {isAuthenticated ? <a href="/tracking" className="block ml-10 text-lg lg:inline-block lg:mt-0 text-gray-600 hover:text-white mr-4">
                            Tracking
                        </a> : <></>}
                    </div>
                    {isAuthenticated ? <a className=" lg:inline-block lg:mt-0 text-gray-600">{user.name}</a> : <></>}
                    {isAuthenticated ? <img className="w-10 mx-6  rounded-full" src={user.picture} alt={user.name}></img> : <></>}
                    <div>
                        {isAuthenticated ? <button onClick={() => logout({ returnTo: window.location.origin })} className="inline-block text-sm px-4 mx-6 py-2 leading-none border rounded text-gray-600 border-white hover:border-transparent hover:text-white hover:bg-gray-700 mt-4 lg:mt-0">Log out</button>
                            : <button onClick={() => loginWithRedirect()} className="inline-block text-sm px-4 py-2 leading-none border rounded mx-6  text-gray-600 border-white hover:border-transparent hover:text-white hover:bg-gray-700 mt-4 lg:mt-0">Log in</button>}
                    </div>
                </div>
            </nav>
        </div>
    )
}





