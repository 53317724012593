import SelectState from './SelectState';
import React, { useEffect, useState } from "react";
import { getTracks, putTrack, filterTrack } from '../../actions';
import { useDispatch, useSelector } from "react-redux";
import Filter from './Filter';
import CheckBox from '../Toggle/CheckBox';
import Toggle from '../Toggle/Toggle';




export default function Tracking() {

    const dispatch = useDispatch();
    const allTracks = useSelector((state) => state.tracks);
    var tracks = [];
    const [refresh, setRefresh] = useState(true)
    const [hide, setHide] = useState(true)
    const [status, setStatus] = useState({
        ssp: "all",
        status: "",
        name: ""
    });
    const [input, setInput] = useState({
        one_tag: "",
        smart_adserver: "",
        pulsepoint: "",
        pubmatic: "",
        Outbrain: "",
        adtelligent: "",
        sovrn: "",
        index_pub: "",
        mcm: "",
        xandr: "",
        improve: "",
        siteId: "",
        enable: ""
    })


    useEffect(() => {
        dispatch(getTracks())
    }, [refresh, dispatch])

    function handleInputChange(e) {
        e.preventDefault();
        setInput({
            [e.target.name]: e.target.value,
            siteId: e.target.id
        })
    }

    useEffect(() => {
        if (input.siteId) {
            dispatch(putTrack(input))
        }
    }, [input, dispatch])

    function handleSubmit(e) {
        e.preventDefault();
        dispatch(filterTrack(status))
    }

    function handleFilterSsp(e) {
        e.preventDefault();
        setStatus({
            ...status,
            ssp: e.target.value
        })
    }

    function handleFilterStatus(e) {
        e.preventDefault();
        setStatus({
            ...status,
            status: e.target.value
        })
    }

    function handleName(e) {
        e.preventDefault();
        setStatus({
            ...status,
            name: e.target.value
        })
    }

    function handleRefresh(e) {
        setStatus({
            ssp: "all",
            status: "",
            name: ""
        })
        if (refresh === false) {
            setRefresh(true)
        } else {
            setRefresh(false)
        }
    }
    function handleHide(e) {
        if (hide === false) {
            setHide(true)
        } else {
            setHide(false)
        }
    }

    function handleCheckBox(e) {
        if (e.target.value === "false") {
            setInput({
                enable: "true",
                siteId: e.target.id
            })
        } else {
            setInput({
                enable: "false",
                siteId: e.target.id
            })
        }
        if (refresh === false) {
            setRefresh(true)
        } else {
            setRefresh(false)
        }
    }


    if (hide) {
        tracks = allTracks.filter(e => e.enable !== false)
    } else {
        tracks = allTracks
    }

    return (
        <div className="text-gray-900 ">
            <div className="text-gray-900">
                <div className="p-4 flex flex justify-between ">
                    <div className="p-4 flex">
                        <h1 className="text-4xl">
                            Tracking
                        </h1>

                    </div>
                    <div className="p-4 flex flex justify-between ">
                        <Filter handleRefresh={handleRefresh} handleFilterSsp={handleFilterSsp} handleSubmit={handleSubmit} handleFilterStatus={handleFilterStatus} name={status.name} selectStatus={status.status} value={status.ssp} handleName={handleName} />

                    </div>
                </div>
                <div className="mt-10 ml-6 flex flex justify-left ">
                    <Toggle handleHide={handleHide} label={"Show Inactive Tracks"} />
                </div>
                <div className="px-3 flex justify-left">
                    <table className=" text-md bg-white shadow-md rounded mb-4 table-fixed">
                        <thead>
                            {status.ssp === "all" ?
                                <tr className="border-b">
                                    <th className="text-left w-10 p-1 px-3"></th>
                                    <th className="text-left w-30 p-1 px-3">id</th>
                                    <th className="text-left w-60 p-1 px-3">Publisher</th>
                                    <th className="text-left w-60 p-1 px-3">Domain</th>
                                    <th className="text-center p-1 px-3">MCM</th>
                                    <th className="text-center p-1 px-3">One Tag</th>
                                    <th className="text-center p-1 px-3">Xandr</th>
                                    <th className="text-center p-1 px-3">Smart AdServer</th>
                                    <th className="text-center p-1 px-3">Improve</th>
                                    <th className="text-center p-1 px-3">Pulsepoint</th>
                                    <th className="text-center p-1 px-3">pubmatic</th>
                                    <th className="text-center p-1 px-3">Outbrain</th>
                                    <th className="text-center p-1 px-3">adtelligent</th>
                                    <th className="text-center p-1 px-3">Sovrn</th>
                                    <th className="text-center p-1 px-3">Index</th>

                                </tr> :
                                <tr className="border-b">
                                    <th className="text-left w-10 p-1 px-3"></th>
                                    <th className="text-left w-30 p-1 px-3">id</th>
                                    <th className="text-left w-60 p-1 px-3">Publisher</th>
                                    <th className="text-left w-60 p-1 px-3">Domain</th>
                                    <th className="text-center p-1 px-3">{status.ssp}</th>
                                </tr>}
                        </thead>
                        <tbody>

                            {tracks && tracks.map((e) => {
                                return (
                                    status.ssp === "all" ?
                                        <tr className={`${e.enable === false ? "text-gray-400 line-through" : ""}   border-b hover:bg-orange-100 bg-gray-100`} >
                                            <td key={e.id + "enable"} className=" px-5"> <CheckBox id={e.siteId} name="enable" handleCheckBox={handleCheckBox} enable={e.enable} /> </td>
                                            <td key={e.id} className="text-left px-5">{e.siteId}</td>
                                            <td key={e.id + "publisher_Name"} className="text-left px-5">{e.site.publisher.publisher_Name}</td>
                                            <td key={e.id + "site.domain"} className=" text-left  px-5">{e.site.domain}</td>
                                            {e.mcm ? <td key={e.id + "mcm"} className="text-left px-5"><SelectState id={e.siteId} name="mcm" handleInputChange={handleInputChange} value={e.mcm} /></td> : ""}
                                            {e.one_tag ? <td key={e.id + "one_tag"} className=" text-left  px-5"><SelectState id={e.siteId} name="one_tag" handleInputChange={handleInputChange} value={e.one_tag} /></td> : ""}
                                            {e.xandr ? <td key={e.id + "xandr"} className="text-left  px-5"><SelectState id={e.siteId} name="xandr" handleInputChange={handleInputChange} value={e.xandr} /></td> : ""}
                                            {e.smart_adserver ? <td key={e.id + "smart_adserver"} className="text-left px-5"><SelectState id={e.siteId} name="smart_adserver" handleInputChange={handleInputChange} value={e.smart_adserver} /></td> : ""}
                                            {e.improve ? <td key={e.id + "improve"} className=" text-left  px-5"><SelectState id={e.siteId} name="improve" handleInputChange={handleInputChange} value={e.improve} /></td> : ""}
                                            {e.pulsepoint ? <td key={e.id + "pulsepoint"} className="text-left  px-5"><SelectState id={e.siteId} name="pulsepoint" handleInputChange={handleInputChange} value={e.pulsepoint} /></td> : ""}
                                            {e.pubmatic ? <td key={e.id + "pubmatic"} className="text-left px-5"><SelectState id={e.siteId} name="pubmatic" handleInputChange={handleInputChange} value={e.pubmatic} /></td> : ""}
                                            {e.Outbrain ? <td key={e.id + "Outbrain"} className=" text-left  px-5"><SelectState id={e.siteId} name="Outbrain" handleInputChange={handleInputChange} value={e.Outbrain} /></td> : ""}
                                            {e.adtelligent ? <td key={e.id + "adtelligent"} className="text-left  px-5"><SelectState id={e.siteId} name="adtelligent" handleInputChange={handleInputChange} value={e.adtelligent} /></td> : ""}
                                            {e.sovrn ? <td key={e.id + "sovrn"} className="text-left px-5"><SelectState id={e.siteId} name="sovrn" handleInputChange={handleInputChange} value={e.sovrn} /></td> : ""}
                                            {e.index_pub ? <td key={e.id + "pub"} className=" text-left  px-5"><SelectState id={e.siteId} name="index_pub" handleInputChange={handleInputChange} value={e.index_pub} /></td> : ""}
                                        </tr> :

                                        <tr className="border-b hover:bg-orange-100 bg-gray-100">
                                            <td key={e.id + "enable"} className=" px-5"> <CheckBox id={e.siteId} name="enable" handleCheckBox={handleCheckBox} enable={e.enable} /> </td>
                                            <td key={e.id} className="text-left px-5">{e.siteId}</td>
                                            <td key={e.id + "publisher_Name"} className="text-left px-5">{e.site.publisher.publisher_Name}</td>
                                            <td key={e.id + "site.domain"} className=" text-left  px-5">{e.site.domain}</td>
                                            {status.ssp === "mcm" ? <td key={e.id + "mcm"} className="text-left px-5"><SelectState id={e.siteId} name="mcm" handleInputChange={handleInputChange} value={e.mcm} /></td> : ""}
                                            {status.ssp === "one_tag" ? <td key={e.id + "one_tag"} className=" text-left  px-5"><SelectState id={e.siteId} name="one_tag" handleInputChange={handleInputChange} value={e.one_tag} /></td> : ""}
                                            {status.ssp === "xandr" ? <td key={e.id + "xandr"} className="text-left  px-5"><SelectState id={e.siteId} name="xandr" handleInputChange={handleInputChange} value={e.xandr} /></td> : ""}
                                            {status.ssp === "smart_adserver" ? <td key={e.id + "smart_adserver"} className="text-left px-5"><SelectState id={e.siteId} name="smart_adserver" handleInputChange={handleInputChange} value={e.smart_adserver} /></td> : ""}
                                            {status.ssp === "improve" ? <td key={e.id + "improve"} className=" text-left  px-5"><SelectState id={e.siteId} name="improve" handleInputChange={handleInputChange} value={e.improve} /></td> : ""}
                                            {status.ssp === "pulsepoint" ? <td key={e.id + "pulsepoint"} className="text-left  px-5"><SelectState id={e.siteId} name="pulsepoint" handleInputChange={handleInputChange} value={e.pulsepoint} /></td> : ""}
                                            {status.ssp === "pubmatic" ? <td key={e.id + "pubmatic"} className="text-left px-5"><SelectState id={e.siteId} name="pubmatic" handleInputChange={handleInputChange} value={e.pubmatic} /></td> : ""}
                                            {status.ssp === "Outbrain" ? <td key={e.id + "Outbrain"} className=" text-left  px-5"><SelectState id={e.siteId} name="Outbrain" handleInputChange={handleInputChange} value={e.Outbrain} /></td> : ""}
                                            {status.ssp === "adtelligent" ? <td key={e.id + "adtelligent"} className="text-left  px-5"><SelectState id={e.siteId} name="adtelligent" handleInputChange={handleInputChange} value={e.adtelligent} /></td> : ""}
                                            {status.ssp === "sovrn" ? <td key={e.id + "sovrn"} className="text-left px-5"><SelectState id={e.siteId} name="sovrn" handleInputChange={handleInputChange} value={e.sovrn} /></td> : ""}
                                            {status.ssp === "index_pub" ? <td key={e.id + "pub"} className=" text-left  px-5"><SelectState id={e.siteId} name="index_pub" handleInputChange={handleInputChange} value={e.index_pub} /></td> : ""}
                                        </tr>
                                )
                            })}
                        </tbody>
                    </table>

                </div>
                {tracks.length === 0 ? <h1 className="text-4xl mt-16">Search not found. Reset filters</h1> : ""}
            </div>
        </div>
    )
}