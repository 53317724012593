import React from 'react';





export default function SelectState({ value, handleInputChange, name, id }) {
  return (
    <div className="relative inline-flex">
      <select onChange={(e) => handleInputChange(e)} id={id} defaultValue={value} name={name} className={` ${value === "No" ? "text-red-500" : ""}  ${value === "Yes" ? "text-green-500" : ""} ${value === "Pending" ? "text-yellow-600" : ""} border border-gray-300 text-xs h-10  pl-4 pr-4 bg-gray-200 hover:border-gray-400 focus:outline-none appearance-none`}>
        <option value="Not Send">NOT SEND</option>
        <option value="Pending" className="text-yellow-600 ">PENDING</option>
        <option value="Yes" className="text-green-500 ">YES</option>
        <option value="No" className="text-red-500 ">NO</option>
      </select>
    </div>
  )

}