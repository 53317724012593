import React, { useState, useEffect } from "react";
import { putSite, getSiteById } from '../../actions';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import swal from 'sweetalert';
import SelectTagComponent from "../SelectTagComponent/SelectTagComponent";




export default function EditSite() {

    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const site = useSelector((state) => state.site);


    const [refresh, setRefresh] = useState(true)
    const [input, setInput] = useState({
        geos: site.geos,
        seller_type: "",
        domain: "",
        id: id,
        loaded: false
    })
    useEffect(() => {
        dispatch(getSiteById(id))

    }, [dispatch, refresh])


    useEffect(() => {
        if (!site.geos) {
            setInput((prev) =>
            ({
                ...prev,
                geos: site.geos,
                loaded: true
            })
            )
        }
    }, [site])


    function handleSubmit(e) {
        e.preventDefault();
        swal({
            title: "Site edited",
            icon: "success",
            text: "The Site was successfully edited"
        })
        dispatch(putSite(input))
        setRefresh(false)
        navigate('/publisher/' + site.publisherId, { replace: true })
    }

    function handleInputChange(e) {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        })
    }

    return (
        <div>
            <h1 className="text-5xl mt-32 font-bold ">
                Edit Site
            </h1>
            <div className="max-w-2xl mx-auto bg-white p-16">
                <form onSubmit={e => handleSubmit(e)}>
                    <div className="flex justify-center">
                    </div>
                    <div className="grid gap-6 mb-6 ">
                        <div>
                            <label className="block text-left pl-1  mb-2 text-lg font-medium text-gray-900 ">Domain</label>
                            <input type="text" onChange={(e) => handleInputChange(e)} name="domain" id="domain" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder={site.domain} />
                        </div>
                        <div >
                            <label className="block text-left pl-1 mb-2 text-lg font-medium text-gray-900 ">Geos</label>
                            <div id="geos" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " >
                                <SelectTagComponent setGeos={geos => setInput(prev => ({ ...prev, geos }))} geos={input.geos} />
                            </div>
                        </div>
                        <div >
                            <label className="block text-left pl-1 mb-2 text-lg font-medium text-gray-900 ">Seller Type</label>
                            <select onChange={(e) => handleInputChange(e)} name="seller_type" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " aria-label="Default select example">
                                <option selected>Select</option>
                                <option value="DIRECT">DIRECT</option>
                                <option value="RESELLER">RESELLER</option>
                            </select>
                        </div>
                    </div>
                    <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center ">Submit</button>
                </form>
            </div>
        </div>
    )
}