import React, { useEffect, useState } from "react";
import { getTags, deleteTag } from '../../actions';
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from 'react-router-dom';
import swal from 'sweetalert';




export default function SiteTags() {

    const { id } = useParams();
    const dispatch = useDispatch();
    const tags = useSelector((state) => state.tags);
    const [refresh, setRefresh]= useState(true)

    useEffect(() => {
        dispatch(getTags(id))
    }, [dispatch, refresh, id])

    function handleDelete(e) {
        swal({
            title: "Do you want delete tag?",
            text: "",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((value) => {
            if (value === true) {
                swal(`Tag ${e.target.name} has been successfully deleted`, {
                    icon: "success",
                });
                dispatch(deleteTag(e.target.value)).then(setRefresh(false))
                
            } else swal(`Tag has not been deleted!`);
        });
    }


    return (
        <div className="text-gray-900 ">
            <div className="text-gray-900">
                <div className="p-4 flex">
                    <h1 className="text-4xl">
                        {tags.domain}
                    </h1>
                    <Link to={'/publisher/sites/addtag/' + id}><button type="button" className="text-sm bg-green-500 hover:bg-green-700 text-white py-2 mx-10 px-6 rounded focus:outline-none focus:shadow-outline">Add Tag</button></Link>
                </div>
            
                <div className="px-3 py-4 flex justify-center">
                    <table className="w-full text-md bg-white shadow-md rounded mb-4 table-fixed">
                        <thead>
                            <tr className="border-b">
                                <th className="text-left w-40 p-3 px-5">Format</th>
                                <th className="text-left w-40  p-3 px-5">Type</th>
                                <th className="text-left p-3 px-5">Size</th>
                                <th className="text-left p-3 px-5">Tag Line</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {tags.site_tags && tags.site_tags.map((e) => {
                                return (
                                    <tr key={"group" + e.id}  className="border-b">
                                        <td key={"format" + e.id} className="text-left p-3 px-5">{e.format}</td>
                                        <td key={"type" + e.id} className=" text-left  p-3 px-5">{e.type}</td>
                                        <td key={"size" + e.id} className="text-left  p-3 px-5">{e.size}</td>
                                        <td key={"line" + e.id} className="text-left  p-3 px-5">{tags.domain+"_"+e.size+"_"+e.format+"_"+(e.type==="Mobile"?"MW":"DK")+"  "+e.size}</td>
                                        <td key={"buttons" + e.id} className="text-left  p-3 px-5 flex justify-end">
                                            <Link to={"/publisher/sites/edittag/" + e.id}><button key={"edit" + e.id}  type="button" className="mr-3 text-sm bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline">Edit</button></Link>
                                            <button key={"delete" + e.id}  type="button" onClick={(e) => handleDelete(e)} value={e.id} className="text-sm bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline">Delete</button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    )
}