import React from 'react';
import './App.css';
import { Routes, Route } from "react-router-dom";
import LandingPage from './components/LandingPage/LandingPage';
import Publishers from './components/Publishers/Publishers';
import NavBar from './components/NavBar/NavBar';
import Sites from './components/Sites/Sites';
import AddPublisher from './components/Publishers/AddPublisher';
import AddSite from './components/Sites/AddSite';
import SiteTags from './components/SiteTags/SiteTags';
import PublisherSites from './components/Publishers/PublisherSites';
import EditPublisher from './components/Publishers/EditPublisher';
import AddTag from './components/SiteTags/AddTags';
import EditTag from './components/SiteTags/EditTags';
import EditSite from './components/Sites/EditSite';
import { useAuth0 } from "@auth0/auth0-react";
import Tracking from './components/Tracking/Tracking';



function App() {
  const { isAuthenticated } = useAuth0();


  return (
    <div className="App">
      <NavBar />

     {isAuthenticated? <Routes>
        <Route exact path='/' element={<LandingPage />} />
        <Route path='/publishers' element={<Publishers />} />
        <Route path='/sites' element={<Sites />} />
        <Route path='publishers/addpublisher' element={<AddPublisher />} />
        <Route path='publisher/addsite/:id' element={<AddSite />} />
        <Route path='publisher/editsite/:id' element={<EditSite />} />
        <Route path='/publisher/sites/:id' element={<SiteTags />} />
        <Route path='/publisher/:id' element={<PublisherSites />} />
        <Route path='/publisher/editpublisher/:id' element={<EditPublisher />} />
        <Route path='/publisher/sites/addtag/:id' element={<AddTag />} />
        <Route path='/publisher/sites/edittag/:id' element={<EditTag />} />
        <Route path='/tracking' element={<Tracking />} />

      </Routes>:
      <Routes>
        <Route exact path='/' element={<LandingPage />} />
      </Routes>}


    </div>
  );
}

export default App;
