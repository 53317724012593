import React from "react";
import { TagsInput } from "react-tag-input-component";
import './styles.css';

export default function SelectTagComponent({ setGeos, geos }) {
  
  console.log("geos :",geos)
  return (
    <div>
      <TagsInput
        value={geos}
        onChange={setGeos}
        name="geos"
        placeHolder="Add geos and press enter"
      />
    </div>
  );
}

