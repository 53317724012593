import React from 'react';





export default function Pagination({currentPage, entriesPerPage, totalEntries, pagination}) {
    const pageNumbers = [];

    for (let i = 0; i < Math.ceil(totalEntries/entriesPerPage); i++) {
        pageNumbers.push(i+1)
        
    }
    return (
        <div>
            <div className="flex flex-col items-center">

                <span className="text-sm text-gray-700 dark:text-gray-400">
                    Showing <span className="font-semibold text-gray-900 dark:text-white">{currentPage===1?currentPage:entriesPerPage*(currentPage-1)+1}</span> to <span className="font-semibold text-gray-900 dark:text-white">{entriesPerPage*currentPage < totalEntries?entriesPerPage*currentPage: totalEntries}</span> of <span className="font-semibold text-gray-900 dark:text-white">{totalEntries}</span> entries
                </span>

                <div className="inline-flex mt-2 xs:mt-0">
                    <button onClick={currentPage > 1?()=>pagination(currentPage -1):()=>pagination(currentPage) } className="py-2 px-4 text-sm font-medium text-white bg-blue-500 rounded-l hover:bg-blue-600">
                        Prev
                    </button>
                    <button onClick={currentPage < pageNumbers.length?()=>pagination(currentPage +1):()=>pagination(currentPage) }  className="py-2 px-4 text-sm font-medium text-white bg-blue-500 rounded-r border-0 border-l border-gray-700 hover:bg-blue-600">
                        Next
                    </button>
                </div>
            </div>
        </div>
    )
}