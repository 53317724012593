import React, { useState } from "react";
import { postPublisher } from '../../actions';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';

export default function AddPublisher() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [input, setInput] = useState({
        publisher_Name: "",
        legal_Entity_Name: "",
        info_Payment: "",
        rev_Share: ""
    })

    function handleSubmit(e) {
        e.preventDefault();
        if (!input.publisher_Name || !input.legal_Entity_Name || !input.info_Payment || !input.rev_Share) {
            e.preventDefault();
            swal({
                title: "You must complete all the information",
                icon: "error"
            })
        } else {
            e.preventDefault();
            swal({
                title: "Publisher created",
                icon: "success",
                text: "The publisher was successfully created"
            })
            dispatch(postPublisher(input))
            setInput({
                publisher_Name: "",
                legal_Entity_Name: "",
                info_Payment: "",
                rev_Share:""
            })
            navigate('/publishers', { replace: true })
        }
    }

    function handleInputChange(e) {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        })
    }

    return (
        <div>
            <h1 className="text-5xl mt-32 font-bold ">
                Add New Publisher
            </h1>
            <div className="max-w-2xl mx-auto bg-white mt-12 p-16">
                <form onSubmit={e => handleSubmit(e)}>
                    <div className="grid gap-6 mb-6 mt-12">
                        <div>
                            <label className="block mb-2 text-left pl-1 text-sm font-medium text-gray-900 ">Legal Entity Name</label>
                            <input type="text" onChange={(e) => handleInputChange(e)} name="legal_Entity_Name" id="legal_Entity_Name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Legal Entity Name" />
                        </div>
                        <div>
                            <label  className="block mb-2 text-left pl-1  text-sm font-medium text-gray-900 ">Publisher Name</label>
                            <input type="text" onChange={(e) => handleInputChange(e)} name="publisher_Name" id="publisher_Name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Publisher Name" />
                        </div>
                        <div>
                            <label className="block mb-2 text-sm text-left pl-1  font-medium text-gray-900 ">Info Payment</label>
                            <input type="text" onChange={(e) => handleInputChange(e)} name="info_Payment" id="info_Payment" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Info Payment" />
                        </div>
                        <div>
                            <label className="block mb-2 text-sm text-left pl-1  font-medium text-gray-900 ">Rev Share %</label>
                            <input type="number" onChange={(e) => handleInputChange(e)} name="rev_Share" id="rev_Share" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Rev Share %" />
                        </div>
                    </div>
                    <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center ">Submit</button>
                </form>
            </div>
        </div>
    )
}